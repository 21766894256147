// const config = {
//   serverBaseUrl: 'https://app.linkcxo.com',
//   baseURL: 'https://www.linkcxo.com/',
// };

// const config = {
//   serverBaseUrl: 'https://app.linkcxo.com',
//   baseURL: 'https://master.d3hxe6oe3uouje.amplifyapp.com/',
// };

const config = {
  serverBaseUrl: 'https://app.dev.linkcxo.com',
  baseURL: 'https://dev.linkcxo.com/',
};



// const config = {
//   serverBaseUrl: 'https://app.dev.linkcxo.com',
//   baseURL: 'http://localhost:3000/',
// };
// const config = {
//   serverBaseUrl: 'http://20.219.223.57',
//   baseURL: 'https://dev.linkcxo.com/',
// };

export default config;

export const LinkedInApi = {
  clientId: '81c214xc47sapn',
  // redirectUrl: "http://linkcxo-development-frontend.s3-website.ap-south-1.amazonaws.com/redirect-web",
  // redirectUrl: "https://web.linkcxo.com/redirect-web",
  redirectUrl: 'https://www.linkcxo.com/redirect-web',
  // redirectUrl: 'http://localhost:3000/redirect-web',
  // redirectUrl: "https://d23mx6z4vkk9zi.cloudfront.net/redirect-web",
  oauthUrl:
    'https://www.linkedin.com/oauth/v2/authorization?response_type=code',
  scope: 'r_liteprofile%20r_emailaddress%20w_member_social',
  state: '123456',
};
export const NodeServer = {
  baseURL: 'https://lx-web-test.tk',
  getUserCredentials: '/getUserCredentials',
};
export const s3config = {
  bucketName: 'lxvideo',
  dirName: 'video1' /* optional */,
  region: 'ap-south-1',
  accessKeyId: 'AKIA5SHIRGMDWNTCOBGP',
  secretAccessKey: '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',
  s3Url: 'https://lxvideo.s3.ap-south-1.amazonaws.com/' /* optional */,
};
