import React, { Fragment, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoInput from '../../components/CxoInput/CxoInput';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FormatIndentIncreaseOutlinedIcon from '@mui/icons-material/FormatIndentIncreaseOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import MenuItem from '@mui/material/MenuItem';
import Icon from '../../assets/icon';
import Menuicon from '../../assets/menuicon';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SearchNetworkCard from '../../components/SearchNetworkCard/SearchNetworkCard';
import SearchArticleCard from '../../components/SearchArticleCard/SearchArticleCard';
import Divider from '@mui/material/Divider';
import * as usernetworkActions from '../../store/usernetwork';
import * as rewardsActions from '../../store/rewards';
import credits_gold from '../../assets/header/Credits_Gold.png';
import External_Link_Active from '../../assets/header/External_Link_Active.png';
import CxoButton from '../../components/CxoButton/CxoButton';
import CxoButtonDisabled from '../../components/CxoButton/CxoButtonDisabled';
import { useNavigate, useLocation } from 'react-router-dom';
import { dateTime } from '../../constants/app';
import moment from 'moment';
import * as messageActions from '../../store/message';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import VoucherDetailsSkeleton from '../../components/Skeleton/VoucherDetailsSkeleton';
import useRazorpay from 'react-razorpay';
import axios from 'axios';
import config from '../../services/apiConfig';
import * as messageAction from '../../store/message';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));
const StyledTab = styled(Tab)((theme) => ({
  color: 'white',
  cursor: 'pointer',
  width: '140px',
  padding: '8px 12px',
  margin: '6px 6px',
  border: 'none',
  borderRadius: '18px',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#262525',
  fontFamily: 'Raleway',
  fontWeight: '600',
  letterSpacing: '0.68px',

  '& .MuiTabs-indicator': {
    color: '#38CD84',
    backgroundColor: '#000014',
  },
  '&.MuiTab-root': {
    borderBottom: 0,
  },
  '& .Mui-disabled': {
    color: 'rgb(245,245,245, 0.6)!important',
  },
  '&:hover': {
    backgroundColor: '#38CD84',
  },
  '&.Mui-selected': {
    backgroundColor: '#38CD84',
    color: '#1F1E1E',
  },
}));

const StyledTabsList = styled(TabsList)((theme) => ({
  minWidth: '320px',
  backgroundColor: '#262525',
  borderRadius: '8px',
  marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  alignContent: 'space-between',
  padding: '16px 16px',
}));

const StyledTabPanel = styled(TabPanel)((theme) => ({
  width: '100%',
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    color: '#FFFFFF',
    fontWeight: '800',
    fontFamily: 'Montserrat',
    '&:hover': {
      color: '#38CD84',
      opacity: 1,
    },
    '&.MuiTab-root': {},
    '&.Mui-selected': {
      color: '#38CD84',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }),
);

const Voucher = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Razorpay = useRazorpay();
  const [value, setValue] = React.useState(1);
  const [showconfirmation, setShowConfirmation] = React.useState(false);
  const [showpaymentSuccess, setShowpaymentSuccess] = React.useState(false);
  const [viewCoupons, setViewCoupons] = React.useState(false);
  const [topay, setTopay] = React.useState(0);
  const [discount, setDiscount] = React.useState(0);
  const [showsuccess, setSuccess] = React.useState(false);
  const [quantity, setQuantity] = React.useState(1);
  const [disabled, setDisabled] = React.useState(false);
  const [couponCode, setCouponCode] = React.useState('');
  const [quantityCoin, setQuantityCoin] = React.useState(0);
  const user = useSelector((state) => state?.user?.UserDetails);
  // const searchresult =  dispatch(searchActions.postSearch(data));
  const rewardVoucherDetail = useSelector(
    (state) => state?.rewards?.rewardVoucherDetail,
  );

  const rewardsVoucherDetails = useSelector(
    (state) => state?.rewards?.rewardsVoucherDetails,
  );

  const rewardsTotalCredits = useSelector(
    (state) => state?.rewards?.rewardsTotalCredits,
  );

  const rewardsPartnerDetails = useSelector(
    (state) => state?.rewards?.rewardsPartnerDetails,
  );
  const rewardDetailsloading = useSelector(
    (state) => state?.rewards?.rewardDetailsloading,
  );
  const noOfAvailableCoupons = useSelector(
    (state) => state?.rewards?.noOfAvailableCoupons,
  );
  useEffect(async () => {}, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(async () => {
    const data = {
      category_id: 0,
    };
    const rewardspartners = await dispatch(
      rewardsActions.rewardsPartnerList(data),
    );
  }, []);

  useEffect(() => {
    if (rewardsTotalCredits?.credits) {
      setQuantityCoin(parseInt(rewardsTotalCredits?.credits));
    }
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
      product_id: rewardsVoucherDetails?.id,
      datetime: dateTime(),
    };
    const rewardVoucherDetails = await dispatch(
      rewardsActions.rewardsVoucherDetails(data),
    );
    if (rewardVoucherDetails?.status === 1) {
      const dataVoucher = {
        product_id: rewardVoucherDetails?.data?.id,
      };
      const noofavailable = await dispatch(
        rewardsActions.rewardsNoOfVoucher(dataVoucher),
      );
    }
  }, []);

  const increaseQuantity = () => {
    setQuantity(parseInt(quantity) + 1);
  };
  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(parseInt(quantity) - 1);
    }
  };

  const increaseQuantityCoin = () => {
    if (quantityCoin < rewardsTotalCredits?.credits) {
      setQuantityCoin(parseInt(quantityCoin) + 1);
    }
  };
  const decreaseQuantityCoin = () => {
    if (quantityCoin > 1) {
      setQuantityCoin(parseInt(quantityCoin) - 1);
    }
  };

  const showConfirmation = () => {
    setShowConfirmation(!showconfirmation);
  };

  const closeViewCoupons = () => {
    setViewCoupons(false);
  };

  const quantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const quantityChangeCoin = (event) => {
    if (
      parseInt(event.target.value) < rewardsTotalCredits?.credits &&
      parseInt(event.target.value) >= 0
    ) {
      setQuantityCoin(event.target.value);
    } else {
      setQuantityCoin(0);
    }
  };
  const couponCodeChange = (event) => {
    setCouponCode(event.target.value);
  };

  const toPay = () => {
    var final_coin = rewardVoucherDetail?.coins_to_used;
    if (user?.membership_type == 0) {
      final_coin =
        parseInt(rewardVoucherDetail?.coins_to_used) -
        parseInt(
          parseInt(rewardVoucherDetail?.coins_to_used) *
            (rewardVoucherDetail?.basic_offer_per / 100),
        );
    } else if (user?.membership_type == 2) {
      final_coin =
        parseInt(rewardVoucherDetail?.coins_to_used) -
        parseInt(
          parseInt(rewardVoucherDetail?.coins_to_used) *
            (rewardVoucherDetail?.silver_offer_per / 100),
        );
    } else if (user?.membership_type == 3) {
      final_coin =
        parseInt(rewardVoucherDetail?.coins_to_used) -
        parseInt(
          parseInt(rewardVoucherDetail?.coins_to_used) *
            (rewardVoucherDetail?.gold_offer_per / 100),
        );
    } else if (user?.membership_type == 4) {
      final_coin =
        parseInt(rewardVoucherDetail?.coins_to_used) -
        parseInt(
          parseInt(rewardVoucherDetail?.coins_to_used) *
            (rewardVoucherDetail?.platinum_offer_per / 100),
        );
    } else {
      final_coin = parseInt(rewardVoucherDetail?.coins_to_used);
    }

    let topay =
      parseInt(rewardsTotalCredits?.credits) / 10 -
      (parseInt(final_coin) / 10) * quantity;

    if (parseInt(quantityCoin) / 10 >= (parseInt(final_coin) / 10) * quantity) {
      return '0';
    } else {
      // setTopay(   ((parseInt(rewardsVoucherDetails?.coins_to_used) / 10) * quantity -parseInt(rewardsTotalCredits?.credits) / 10).toFixed(2) * 100 )
      if (parseInt(quantityCoin) < 0) {
        return ((parseInt(final_coin) / 10) * quantity - discount).toFixed(2);
      } else if (
        (parseInt(final_coin) / 10) * quantity - discount >=
        parseInt(quantityCoin) / 10
      ) {
        return (
          (parseInt(final_coin) / 10) * quantity -
          discount -
          parseInt(quantityCoin) / 10
        ).toFixed(2);
      } else if (
        (parseInt(final_coin) / 10) * quantity - discount <
        parseInt(quantityCoin) / 10
      ) {
        return '0';
      }
    }
  };

  const checkBalance = () => {
    var final_coin = rewardVoucherDetail?.coins_to_used;
    if (user?.membership_type == 0) {
      final_coin =
        parseInt(rewardVoucherDetail?.coins_to_used) -
        parseInt(
          parseInt(rewardVoucherDetail?.coins_to_used) *
            (rewardVoucherDetail?.basic_offer_per / 100),
        );
    } else if (user?.membership_type == 2) {
      final_coin =
        parseInt(rewardVoucherDetail?.coins_to_used) -
        parseInt(
          parseInt(rewardVoucherDetail?.coins_to_used) *
            (rewardVoucherDetail?.silver_offer_per / 100),
        );
    } else if (user?.membership_type == 3) {
      final_coin =
        parseInt(rewardVoucherDetail?.coins_to_used) -
        parseInt(
          parseInt(rewardVoucherDetail?.coins_to_used) *
            (rewardVoucherDetail?.gold_offer_per / 100),
        );
    } else if (user?.membership_type == 4) {
      final_coin =
        parseInt(rewardVoucherDetail?.coins_to_used) -
        parseInt(
          parseInt(rewardVoucherDetail?.coins_to_used) *
            (rewardVoucherDetail?.platinum_offer_per / 100),
        );
    } else {
      final_coin = parseInt(rewardVoucherDetail?.coins_to_used);
    }

    if (
      parseInt(quantityCoin) / 10 >=
      (parseInt(final_coin) / 10) * quantity - discount
    )
      return true;
    else {
      return false;
    }
  };
  const purchaseVoucher = async () => {
    const data = {
      user_id: user?.id,
      product_id: rewardsVoucherDetails?.id,
      quantity: quantity,
      partner_id: rewardVoucherDetail?.partner_id,
      coupon_code: couponCode,
      datetime: dateTime(),
    };
    const rewardsredeem = await dispatch(
      rewardsActions.rewardsRedeemVoucher(data),
    );

    if (rewardsredeem?.status === 1) {
      setShowpaymentSuccess(false);
      setSuccess(true);

      const datavoucher = {
        user_id: user?.id,
        product_id: rewardsVoucherDetails?.id,
        datetime: dateTime(),
      };
      const rewardVoucherDetails = await dispatch(
        rewardsActions.rewardsVoucherDetails(datavoucher),
      );
    }
  };

  const payVoucher = () => {};
  const visitWebsite = () => {
    if (rewardVoucherDetail.partner_website) {
      window.open(rewardVoucherDetail.partner_website, '_blank');
    }
  };

  const applyCoupon = async (e) => {
    if (couponCode?.length > 0) {
      const data = {
        user_id: user?.id,
        product_id: rewardsVoucherDetails?.id,
        coupon_code: couponCode,
        datetime: dateTime(),
      };
      const rewardVoucherDetails = await dispatch(
        rewardsActions.rewardsValidityCheck(data),
      );
      if (rewardVoucherDetails?.status === 1) {
        // const dataVoucher = {
        //   product_id: rewardVoucherDetails?.data?.id,
        // };
        // const noofavailable = await dispatch(
        //   rewardsActions.rewardsNoOfVoucher(dataVoucher),

        // );
        if (
          rewardVoucherDetails?.data?.coupon_message &&
          rewardVoucherDetails?.data?.coupon_discount != 0
        ) {
          console.log('discount', rewardVoucherDetails?.data?.coupon_discount);
          setDiscount(rewardVoucherDetails?.data?.coupon_discount);
        } else {
          const msg = rewardVoucherDetails?.data?.coupon_message;
          const sendmessage = await dispatch(
            messageAction.messagefromComponent(msg, 'error'),
          );
        }
      }
    }
  };
  const copyVoucher = () => {
    navigator.clipboard.writeText(rewardsVoucherDetails?.voucher_code);
    document.execCommand('copy');
  };

  const redirectVoucher = () => {
    navigate('/rewards/my/vouchers', { state: { tab: 1 } });
    setShowConfirmation(!showconfirmation);
  };

  const razorPaySuccess = async (res) => {
    const data = {
      user_id: user?.id,
      product_id: rewardsVoucherDetails?.id,
      datetime: dateTime(),
    };
    const rewardVoucherDetails = await dispatch(
      rewardsActions.rewardsVoucherDetails(data),
    );
    setShowpaymentSuccess(true);
    setShowConfirmation(true);
    setDisabled(false);
    purchaseVoucher();
  };

  const razorPayFailed = (error) => {
    setDisabled(false);
  };
  const orderId = () => {};
  const copyLink = async (code) => {
    if (user?.isVerified) {
      navigator.clipboard.writeText(code);
      document.execCommand('copy');
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const viewVoucher = () => {
    setViewCoupons(true);
  };

  const showInterested = async () => {
    const data = {
      user_id: user?.id,
      product_id: rewardsVoucherDetails?.id,
    };
    const rewardVoucherDetails = await dispatch(
      rewardsActions.rewardsInterest(data),
    );
    if (rewardVoucherDetails?.status === 1) {
      const msg = 'Thank you for Showing interest';
      const sendmessage = await dispatch(
        messageAction.messagefromComponent(msg, 'success'),
      );
    }
  };

  const convertLinks = (input) => {
    if (input) {
      let text = input;
      const linksFound = text.match(/(?:www|https?)[^\s]+/g);
      const aLink = [];

      if (linksFound != null) {
        for (let i = 0; i < linksFound.length; i++) {
          let replace = linksFound[i];
          if (!linksFound[i].match(/(http(s?)):\/\//)) {
            replace = 'http://' + linksFound[i];
          }
          let linkText = replace.split('/')[2];
          let originalText = linksFound[i];
          if (linkText.substring(0, 3) == 'www') {
            linkText = linkText.replace('www.', '');
          }
          if (linkText.match(/youtu/)) {
            let youtubeID = replace.split('/').slice(-1)[0];
            aLink.push(
              '<div class="video-wrapper"><iframe src="https://www.youtube.com/embed/' +
                youtubeID +
                '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
            );
          } else if (linkText.match(/vimeo/)) {
            let vimeoID = replace.split('/').slice(-1)[0];
            aLink.push(
              '<div class="video-wrapper"><iframe src="https://player.vimeo.com/video/' +
                vimeoID +
                '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>',
            );
          } else {
            aLink.push(
              '<a href="' +
                replace +
                '" target="_blank">' +
                originalText +
                '</a>',
            );
          }
          text = text
            .split(linksFound[i])
            .map((item) => {
              return aLink[i].includes('iframe') ? item.trim() : item;
            })
            .join(aLink[i]);
        }
        return text;
      } else {
        return input;
      }
    }
  };

  const purchasingAmount = () => {
    console.log('purchasingAmount', user);
    if (user?.membership_type == 0) {
      return (
        'Rs' +
        (parseInt(rewardVoucherDetail?.mininum_amount_to_redeem) -
          parseInt(
            parseInt(rewardVoucherDetail?.mininum_amount_to_redeem) *
              (rewardVoucherDetail?.basic_offer_per / 100),
          ))
      );
    } else if (user?.membership_type == 2) {
      return (
        parseInt(rewardVoucherDetail?.mininum_amount_to_redeem) -
        parseInt(
          parseInt(rewardVoucherDetail?.mininum_amount_to_redeem) *
            (rewardVoucherDetail?.silver_offer_per / 100),
        )
      );
    } else if (user?.membership_type == 3) {
      return (
        parseInt(rewardVoucherDetail?.mininum_amount_to_redeem) -
        parseInt(
          parseInt(rewardVoucherDetail?.mininum_amount_to_redeem) *
            (rewardVoucherDetail?.gold_offer_per / 100),
        )
      );
    } else if (user?.membership_type == 4) {
      return (
        parseInt(rewardVoucherDetail?.mininum_amount_to_redeem) -
        parseInt(
          parseInt(rewardVoucherDetail?.mininum_amount_to_redeem) *
            (rewardVoucherDetail?.platinum_offer_per / 100),
        )
      );
    } else {
      return 'Rs' + parseInt(rewardVoucherDetail?.mininum_amount_to_redeem);
    }
    // if(membership_type
    // parseInt(rewardsVoucherDetails?.mininum_amount_to_redeem) *
  };

  const purchasingCoins = () => {
    if (user?.membership_type == 0) {
      return (
        parseInt(rewardVoucherDetail?.coins_to_used) -
        parseInt(
          parseInt(rewardVoucherDetail?.coins_to_used) *
            (rewardVoucherDetail?.basic_offer_per / 100),
        )
      );
    } else if (user?.membership_type == 1) {
      return (
        parseInt(rewardVoucherDetail?.coins_to_used) -
        parseInt(
          parseInt(rewardVoucherDetail?.coins_to_used) *
            (rewardVoucherDetail?.basic_offer_per / 100),
        )
      );
    } else if (user?.membership_type == 2) {
      return (
        parseInt(rewardVoucherDetail?.coins_to_used) -
        parseInt(
          parseInt(rewardVoucherDetail?.coins_to_used) *
            (rewardVoucherDetail?.silver_offer_per / 100),
        )
      );
    } else if (user?.membership_type == 3) {
      return (
        parseInt(rewardVoucherDetail?.coins_to_used) -
        parseInt(
          parseInt(rewardVoucherDetail?.coins_to_used) *
            (rewardVoucherDetail?.gold_offer_per / 100),
        )
      );
    } else if (user?.membership_type == 4) {
      return (
        parseInt(rewardVoucherDetail?.coins_to_used) -
        parseInt(
          parseInt(rewardVoucherDetail?.coins_to_used) *
            (rewardVoucherDetail?.platinum_offer_per / 100),
        )
      );
    } else {
      return (
        parseInt(rewardVoucherDetail?.coins_to_used) -
        parseInt(
          parseInt(rewardVoucherDetail?.coins_to_used) *
            (rewardVoucherDetail?.basic_offer_per / 100),
        )
      );
    }
    // if(membership_type
    // parseInt(rewardsVoucherDetails?.mininum_amount_to_redeem) *
  };

  const handlePayment = useCallback(() => {
    setDisabled(true);
    const order = 455;
    let topay = 0;
    if (
      parseInt(quantityCoin) / 10 >=
      (parseInt(purchasingCoins()) / 10) * quantity - discount
    ) {
      topay = 0;
    } else {
      // setTopay(   ((parseInt(rewardsVoucherDetails?.coins_to_used) / 10) * quantity -parseInt(rewardsTotalCredits?.credits) / 10).toFixed(2) * 100 )
      if (
        (parseInt(purchasingCoins()) / 10) * quantity - discount >
        parseInt(quantityCoin) / 10
      ) {
        topay =
          (
            (parseInt(purchasingCoins()) / 10) * quantity -
            discount -
            parseInt(quantityCoin) / 10
          ).toFixed(2) * 100;
      } else {
        topay = 0;
      }
      console.log('pay', rewardsTotalCredits?.credits, topay);
    }
    // rzp_test_bbIfwGw718hTLk rzp_live_S9XA2i9Ox4zjp5
    let options = {
      // key: 'rzp_live_S9XA2i9Ox4zjp5',
      key: 'rzp_test_0uFcavHKXDrPCV',
      amount: parseInt(topay),
      currency: 'INR',
      name: 'Linkcxo',
      description: 'Voucher',
      image: 'https://linkcxo-logo.s3.ap-south-1.amazonaws.com/logo.png',
      order_id: '',
      handler: (res) => {
        return razorPaySuccess();
        console.log(res);
      },
      prefill: {
        name: user?.first_name + ' ' + user?.last_name,
        email: user?.email,
        contact: user?.mobile,
      },
      notes: {
        address: '',
      },
      theme: {
        color: '#38CD84',
      },
    };

    axios
      .post(config?.serverBaseUrl + '/create_order', {
        amount: parseInt(topay),
        currency: 'INR',
        receipt: 'rec_pewep34' + user?.id,
        notes: 'lang',
        user_id: user?.id,
        tag: 'credit',
        offer_id: rewardVoucherDetail?.id,
      })
      .then((res) => {
        console.log('res', res);
        setShowConfirmation(false);
        options.order_id = res.data.id;
        options.amount = res.data.amount;
        console.log(options);
        const rzpay = new Razorpay(options);
        rzpay.on('payment.failed', function (response) {
          return razorPayFailed(response);
        });
        rzpay.open();
      })
      .catch((e) => console.log(e));
  }, [Razorpay, quantity, razorPaySuccess, razorPayFailed]);

  if (rewardDetailsloading) {
    return <VoucherDetailsSkeleton />;
  } else {
    return (
      <Fragment>
        {viewCoupons && (
          <Box
            sx={{
              width: '100%',
              height: '100vh',
              position: 'fixed',
              zIndex: '3001',
            }}>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={viewCoupons}>
              <Box
                sx={{
                  width: '80%',
                  backgroundColor: '#1c1c1c',
                  borderRadius: '10px',
                  maxWidth: '700px',
                }}>
                <Stack spacing={0} sx={{ width: '100%', maxWidth: '700px' }}>
                  <Fragment>
                    <Stack spacing={0} direction="row">
                      <Box
                        px={3}
                        pt={2}
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: '24px',
                        }}></Box>
                      <Box
                        px={3}
                        pt={2}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <CloseIcon
                          sx={{
                            color: '#ffffff',
                            cursor: 'pointer',
                            fontSize: '24',
                          }}
                          onClick={closeViewCoupons}
                        />
                      </Box>
                    </Stack>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        paddingBottom: '64px',
                      }}>
                      <Stack
                        direction="column"
                        spacing={2}
                        sx={{ justifyContent: 'center', alignItems: 'center' }}>
                        <Box>
                          <Typography variant="h2s" component="h2s">
                            Voucher Codes
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            width: '100%',
                            maxHeight: '60vh',
                            overflowY: 'auto',
                          }}>
                          {rewardsVoucherDetails?.coupons?.length > 0 &&
                            rewardsVoucherDetails?.coupons?.map(
                              (code, index) => {
                                return (
                                  <Box
                                    onClick={() => copyLink(code)}
                                    sx={{
                                      width: '300px',
                                      height: '40px',
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      borderRadius: '25px',
                                      backgroundColor: '#333',
                                      border: '1px solid #3DCD84',
                                    }}>
                                    <Box
                                      p={1}
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}>
                                      <Typography variant="h2s" component="h2s">
                                        {code?.voucher_code}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        backgroundColor: '#38CD84',
                                        color: '#262525',
                                        width: '72px',
                                        borderRadius: '25px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}>
                                      <Typography variant="h2" component="h2">
                                        Copy
                                      </Typography>
                                    </Box>
                                  </Box>
                                );
                              },
                            )}
                        </Box>
                      </Stack>
                    </Box>
                  </Fragment>
                </Stack>
              </Box>
            </Backdrop>
          </Box>
        )}

        {showconfirmation && (
          <Box
            sx={{
              width: '100%',
              height: '100vh',
              position: 'fixed',
              zIndex: '3001',
            }}>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={showconfirmation}>
              <Box
                sx={{
                  width: '80%',
                  backgroundColor: '#1c1c1c',
                  borderRadius: '10px',
                  maxWidth: '700px',
                }}>
                <Stack spacing={0} sx={{ width: '100%', maxWidth: '700px' }}>
                  {!showsuccess && (
                    <Fragment>
                      <Stack spacing={0} direction="row">
                        <Box
                          px={3}
                          pt={2}
                          sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginLeft: '24px',
                          }}></Box>
                        <Box
                          px={3}
                          pt={2}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                          <CloseIcon
                            sx={{
                              color: '#ffffff',
                              cursor: 'pointer',
                              fontSize: '24',
                            }}
                            onClick={showConfirmation}
                          />
                        </Box>
                      </Stack>
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                        }}>
                        <Stack
                          direction="column"
                          spacing={2}
                          sx={{
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                          {!showpaymentSuccess && (
                            <Typography variant="h2s" component="h2s">
                              {checkBalance()
                                ? 'Purchase Voucher'
                                : "You don't have sufficient  points to purchase this voucher"}
                            </Typography>
                          )}

                          {noOfAvailableCoupons === 0 && (
                            <Typography variant="h2s" component="h2s">
                              Coupon will be sent to you within 24 Hours
                            </Typography>
                          )}
                        </Stack>
                      </Box>
                    </Fragment>
                  )}

                  {showpaymentSuccess && (
                    <Stack>
                      <Box
                        px={3}
                        pt={4}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                        }}>
                        <Typography gutterBottom variant="h5" component="h5">
                          Payment Successful
                        </Typography>
                      </Box>
                      <Stack direction="row" justifyContent="center">
                        <Box
                          py={2}
                          px={3}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}></Box>
                      </Stack>
                    </Stack>
                  )}
                  {showsuccess && (
                    <Stack>
                      <Box
                        px={3}
                        pt={4}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                        }}>
                        <Typography gutterBottom variant="h5" component="h5">
                          Thank you for purchasing this voucher. Please check
                          your email for the redeeming process.
                        </Typography>
                      </Box>
                      <Stack direction="row" justifyContent="center">
                        <Box
                          py={2}
                          px={3}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}>
                          <CxoButton label="OK" onClick={redirectVoucher} />
                        </Box>
                      </Stack>
                    </Stack>
                  )}

                  {!showsuccess && !showpaymentSuccess && (
                    <Stack>
                      <Box
                        px={3}
                        pt={2}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                        }}>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="h5"></Typography>
                      </Box>
                      <Stack direction="row" justifyContent="space-between">
                        <Box pl={3}>
                          <Stack direction="row" spacing={2}>
                            <Typography variant="h2" component="h2">
                              Coins that you want to use
                            </Typography>
                            <Box pl={1} sx={{ zIndex: '3000' }}>
                              <div className="input-counter-counter">
                                <span
                                  className="minus-btn"
                                  onClick={decreaseQuantityCoin}>
                                  <RemoveIcon sx={{ color: '#000' }} />
                                </span>
                                <input
                                  type="text"
                                  value={quantityCoin}
                                  onChange={quantityChangeCoin}
                                />
                                <span
                                  className="plus-btn"
                                  onClick={increaseQuantityCoin}>
                                  <AddIcon sx={{ color: '#000' }} />
                                </span>
                              </div>
                            </Box>
                            {/* <Stack direction="row" spacing={0.5}>
                              <Typography
                                variant="h2"
                                component="h2"
                                sx={{
                                  color: '#F5BD02',
                                  fontFamily: 'Open Sans',
                                }}>
                                {rewardsTotalCredits?.credits
                                  ? parseInt(
                                      rewardsTotalCredits?.credits,
                                    )?.toLocaleString()
                                  : parseInt(
                                      0
                                     )}
                              </Typography>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  height: '14px',
                                  maxWidth: 'min-content',
                                  width: '14px',
                                  paddingTop: '4px',
                                }}>
                                <img alt="creditgold" src={credits_gold} />
                              </Box>
                            </Stack> */}
                          </Stack>
                        </Box>

                        <Box pr={3}>
                          <Typography
                            variant="h2s"
                            component="h2s"
                            sx={{
                              color: '#F5BD02',
                              fontFamily: 'Open Sans',
                            }}>
                            Rs{' '}
                            {quantityCoin
                              ? (parseInt(quantityCoin) / 10)?.toLocaleString()
                              : 0}
                          </Typography>
                        </Box>
                      </Stack>

                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{ paddingTop: '16px' }}>
                        <Box pl={3}>
                          <Stack direction="row" spacing={2}>
                            <Typography variant="h2" component="h2">
                              Voucher Price
                            </Typography>

                            {/* <Stack direction="row" spacing={0.5}>
                              <Typography
                                variant="h2"
                                component="h2"
                                sx={{
                                  color: '#F5BD02',
                                  fontFamily: 'Open Sans',
                                }}>
                                {purchasingCoins()
                                  ? parseInt(
                                      purchasingCoins(),
                                    )?.toLocaleString()
                                  : 0}
                              </Typography>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  height: '14px',
                                  maxWidth: 'min-content',
                                  width: '14px',
                                  paddingTop: '4px',
                                }}>
                                <img alt="creditgold" src={credits_gold} />
                              </Box>
                            </Stack> */}
                          </Stack>
                        </Box>

                        <Box pr={3}>
                          <Typography
                            variant="h2s"
                            component="h2s"
                            sx={{
                              color: '#F5BD02',
                              fontFamily: 'Open Sans',
                            }}>
                            Rs{' '}
                            {purchasingCoins()
                              ? (
                                  parseInt(purchasingCoins()) / 10
                                )?.toLocaleString()
                              : 0}
                          </Typography>
                        </Box>
                      </Stack>

                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{ paddingTop: '16px' }}>
                        <Box pl={3}>
                          <Stack direction="row" spacing={2}>
                            <Typography variant="h2" component="h2">
                              Select Quantity
                            </Typography>
                            <Box pl={1} sx={{ zIndex: '3000' }}>
                              <div className="input-counter">
                                <span
                                  className="minus-btn"
                                  onClick={decreaseQuantity}>
                                  <RemoveIcon sx={{ color: '#000' }} />
                                </span>
                                <input
                                  type="text"
                                  value={quantity}
                                  onChange={quantityChange}
                                />
                                <span
                                  className="plus-btn"
                                  onClick={increaseQuantity}>
                                  <AddIcon sx={{ color: '#000' }} />
                                </span>
                              </div>
                            </Box>
                          </Stack>
                        </Box>

                        <Box pr={3}>
                          <Typography
                            variant="h2s"
                            component="h2s"
                            sx={{
                              color: '#F5BD02',
                              fontFamily: 'Open Sans',
                            }}>
                            Rs{' '}
                            {purchasingCoins()
                              ? (
                                  (parseInt(purchasingCoins()) / 10) *
                                  quantity
                                )?.toLocaleString()
                              : 0}
                          </Typography>
                        </Box>
                      </Stack>

                      {/* <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{ paddingTop: '16px' }}>
                        <Box pl={3}>
                          <Stack direction="row" spacing={2}>
                            <Typography variant="h2" component="h2">
                              Select Quantity
                            </Typography>
                            <Box pl={1} sx={{ zIndex: '3000' }}>
                              <FormControl
                                variant="standard"
                                fullWidth
                                sx={{ zIndex: '3000' }}>
                                <select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={quantity}
                                  label="Industry List"
                                  onChange={quantityChange}
                                  size="small"
                                  sx={{ width: '96%', zIndex: '3000' }}
                                  style={{
                                    backgroundColor: '#1f1e1e',
                                    color: '#ffffff',
                                  }}>
                                  {noOfAvailableCoupons > 5 && (
                                    <>
                                      <option value="1" sx={{ zIndex: '3000' }}>
                                        1
                                      </option>
                                      <option value="2" sx={{ zIndex: '3000' }}>
                                        2
                                      </option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                      <option value="5">5</option>
                                    </>
                                  )}

                                  {noOfAvailableCoupons <= 5 &&
                                    noOfAvailableCoupons >= 0 &&
                                    [...Array(noOfAvailableCoupons)].map(
                                      (elementInArray, index) => (
                                        <option
                                          value={index + 1}
                                          sx={{ zIndex: '3000' }}>
                                          {index + 1}
                                        </option>
                                      ),
                                    )}
                                  {noOfAvailableCoupons === 0 && (
                                    <>
                                      <option value="1" sx={{ zIndex: '3000' }}>
                                        1
                                      </option>
                                      <option value="2" sx={{ zIndex: '3000' }}>
                                        2
                                      </option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                      <option value="5">5</option>
                                    </>
                                  )}
                                </select>
                              </FormControl>
                            </Box>
                          </Stack>
                        </Box>

                        <Box pr={3}>
                          <Typography
                            variant="h2s"
                            component="h2s"
                            sx={{
                              color: '#F5BD02',

                              fontFamily: 'Open Sans',
                            }}>
                            Rs{' '}
                            {rewardsVoucherDetails?.coins_to_used
                              ? (
                                  (parseInt(
                                    rewardsVoucherDetails?.coins_to_used,
                                  ) /
                                    10) *
                                  quantity
                                )?.toLocaleString()
                              : 0}
                          </Typography>
                        </Box>
                      </Stack> */}

                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{ paddingTop: '16px' }}>
                        <Box pl={3}>
                          <Stack direction="row" spacing={2}>
                            <Typography variant="h2" component="h2">
                              Coupon code
                            </Typography>
                            <Box pl={1} sx={{ zIndex: '3000' }}>
                              <div className="input-counter-code">
                                <input
                                  type="text"
                                  value={couponCode}
                                  onChange={couponCodeChange}
                                />
                              </div>
                            </Box>
                            <Box
                              onClick={applyCoupon}
                              sx={{ cursor: 'pointer' }}>
                              <span> Apply</span>
                            </Box>
                          </Stack>
                        </Box>

                        <Box pr={3}></Box>
                      </Stack>

                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{ paddingTop: '16px' }}>
                        <Box pl={3}>
                          <Stack>
                            <Stack direction="row" spacing={2}>
                              <Typography variant="h2" component="h2">
                                To pay
                              </Typography>
                              <Stack direction="row" spacing={0.5}>
                                <Typography
                                  variant="h2"
                                  component="h2"
                                  sx={{
                                    color: '#F5BD02',

                                    fontFamily: 'Open Sans',
                                  }}>
                                  {purchasingCoins()
                                    ? (
                                        parseInt(purchasingCoins()) * quantity
                                      )?.toLocaleString()
                                    : 0}
                                </Typography>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    height: '14px',
                                    maxWidth: 'min-content',
                                    width: '14px',
                                    paddingTop: '4px',
                                  }}>
                                  <img alt="creditgold" src={credits_gold} />
                                </Box>
                              </Stack>
                            </Stack>
                            <Box pt={0.5}>
                              <Typography variant="h2" component="h2">
                                (10 Points = Rs. 1)
                              </Typography>
                            </Box>
                          </Stack>
                        </Box>

                        <Box pr={3}>
                          <Typography
                            variant="h2s"
                            component="h2s"
                            sx={{
                              color: '#F5BD02',
                              fontFamily: 'Open Sans',
                            }}>
                            Rs {toPay()}
                          </Typography>
                        </Box>
                      </Stack>

                      <Stack
                        direction="row"
                        justifyContent="center"
                        sx={{ paddingTop: '16px' }}>
                        <Box
                          py={2}
                          px={3}
                          pb={5}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}>
                          {checkBalance() && !disabled ? (
                            <CxoButton
                              label="Purchase"
                              onClick={purchaseVoucher}
                            />
                          ) : (
                            <CxoButton
                              label={'Pay ' + toPay()}
                              onClick={handlePayment}
                            />
                          )}

                          {checkBalance() && disabled ? (
                            <CxoButtonDisabled
                              label={'Pay ' + toPay()}
                              onClick={purchaseVoucher}
                            />
                          ) : (
                            ''
                          )}
                        </Box>
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              </Box>
            </Backdrop>
          </Box>
        )}

        <Box px={1} py={1}>
          <Paper sx={{ backgroundColor: '#262525', backgroundImage: 'none' }}>
            <Card
              sx={{
                width: '100%',
                position: 'relative',
                backgroundColor: '#262525',
                backgroundImage: 'none',
              }}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '60px',
                  padding: '16px',
                }}>
                <Typography
                  variant="h5s"
                  component="h5s"
                  sx={{
                    fontSize: '18px',
                    lineHeight: '18px',
                    letterSpacing: '0.23px',
                    fontWeight: '600',
                  }}>
                  Voucher
                </Typography>
              </Box>
              <CardMedia
                component="img"
                alt="Voucher Details"
                height="300"
                sx={{
                  objectFit: 'contain',
                  padding: '16px',
                }}
                image={
                  rewardVoucherDetail?.image ? rewardVoucherDetail?.image : ''
                }
              />
              <CardContent>
                <Box p={2}>
                  <Stack direction="column">
                    <Box>
                      <Typography
                        variant="h2s"
                        component="h2s"
                        sx={{
                          fontSize: '20px',
                          lineHeight: '23px',
                          letterSpacing: '0.31px',
                          fontWeight: '600',
                        }}>
                        {rewardVoucherDetail?.name
                          ? rewardVoucherDetail?.name
                          : ''}
                      </Typography>
                    </Box>

                    <Box pt={1}>
                      {rewardVoucherDetail?.partner_details ? (
                        <Box
                          sx={{
                            fontWeight: '500',
                            fontSize: '15px',
                            color: 'rgba(255, 255,255,1)',
                            whiteSpace: 'pre-line',
                            lineHeight: '21px',
                            letterSpacing: '1px',

                            '@media (max-width: 500px)': {
                              fontSize: '13px',
                            },
                          }}
                          dangerouslySetInnerHTML={{
                            __html: convertLinks(
                              rewardVoucherDetail?.partner_details,
                            ),
                          }}></Box>
                      ) : (
                        ''
                      )}
                    </Box>
                    <Box py={2}>
                      <Divider />
                    </Box>
                    <Box>
                      <Typography
                        variant="h2s"
                        component="h2s"
                        sx={{
                          fontSize: '20px',
                          lineHeight: '23px',
                          letterSpacing: '0.31px',
                          fontWeight: '600',
                        }}>
                        Product Details
                      </Typography>
                    </Box>
                    <Box pt={1}>
                      {rewardVoucherDetail?.product_details ? (
                        <Box
                          sx={{
                            fontWeight: '500',
                            fontSize: '15px',
                            color: 'rgba(255, 255,255,1)',
                            whiteSpace: 'pre-line',
                            lineHeight: '21px',
                            letterSpacing: '1px',

                            '@media (max-width: 500px)': {
                              fontSize: '13px',
                            },
                          }}
                          dangerouslySetInnerHTML={{
                            __html: convertLinks(
                              rewardVoucherDetail?.product_details,
                            ),
                          }}></Box>
                      ) : (
                        ''
                      )}
                    </Box>
                  </Stack>

                  <Box py={2}>
                    <Divider />
                  </Box>

                  <Box py={2}>
                    <Box>
                      <Typography
                        variant="h2s"
                        component="h2s"
                        sx={{
                          fontSize: '20px',
                          whiteSpace: 'pre-line',
                          lineHeight: '23px',
                          letterSpacing: '0.31px',
                          fontWeight: '600',
                        }}>
                        {rewardVoucherDetail?.offer_details
                          ? rewardVoucherDetail?.offer_details
                          : ''}
                      </Typography>
                    </Box>
                    <Stack
                      direction="row"
                      sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Stack direction="column">
                        <Box pt={2}>
                          <Typography variant="h6" component="h6">
                            {rewardVoucherDetail?.applicalble_on
                              ? rewardVoucherDetail?.applicalble_on
                              : ''}
                          </Typography>
                        </Box>
                        {rewardsVoucherDetails?.coins_to_used != 0 && (
                          <Box
                            spacing={2}
                            py={2}
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}>
                            <Typography
                              variant="h2"
                              component="h2"
                              sx={{
                                fontSize: '18px',
                                lineHeight: '23px',
                                letterSpacing: '0.31px',
                                fontWeight: '600',
                                fontFamily: 'Open Sans',
                              }}>
                              {rewardsVoucherDetails?.quantity
                                ? 'Purchased with'
                                : 'Purchase with'}
                            </Typography>
                            <Box pl={1}>
                              <Typography
                                variant="h2s"
                                component="h2s"
                                sx={{
                                  fontSize: '18px',
                                  lineHeight: '23px',
                                  letterSpacing: '0.31px',
                                  color: '#F5BD02',
                                  fontWeight: '600',
                                }}>
                                {purchasingCoins()}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '18px',
                                maxWidth: 'min-content',
                                width: '18px',
                                paddingLeft: '8px',
                              }}>
                              <img alt="creditgold" src={credits_gold} />
                            </Box>
                          </Box>
                        )}
                        {rewardsVoucherDetails?.quantity ? (
                          <Fragment>
                            <Box
                              spacing={2}
                              py={2}
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}>
                              <Typography
                                variant="h2"
                                component="h2"
                                sx={{
                                  fontSize: '18px',
                                  lineHeight: '23px',
                                  letterSpacing: '0.31px',
                                  fontWeight: '600',
                                }}>
                                Purchased
                              </Typography>
                              <Box pl={1}>
                                <Typography
                                  variant="h2s"
                                  component="h2s"
                                  sx={{
                                    fontSize: '18px',
                                    lineHeight: '23px',
                                    letterSpacing: '0.31px',
                                    fontWeight: '600',
                                    fontFamily: 'Open Sans',
                                  }}>
                                  {rewardsVoucherDetails?.quantity > 1
                                    ? parseInt(
                                        rewardsVoucherDetails?.quantity,
                                      ) + ' Voucher'
                                    : parseInt(
                                        rewardsVoucherDetails?.quantity,
                                      ) + ' Vouchers'}
                                </Typography>
                              </Box>
                            </Box>
                          </Fragment>
                        ) : (
                          <> </>
                        )}

                        <Stack
                          direction="row"
                          spacing={0.6}
                          sx={{ cursor: 'pointer', paddingTop: '4px' }}
                          onClick={visitWebsite}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              paddingTop: '2px',
                              height: '14px',
                              maxWidth: 'min-content',
                              width: '14px',
                              marginRight: '8px',
                              marginTop: '2px',
                            }}
                            onClick={visitWebsite}>
                            <img alt="creditgold" src={External_Link_Active} />
                          </Box>

                          <Typography
                            variant="h2s"
                            component="h2s"
                            sx={{
                              fontSize: '14px',
                              lineHeight: '23px',
                              fontWeight: '400',
                            }}>
                            Visit
                          </Typography>
                          <Typography
                            variant="h2s"
                            component="h2s"
                            sx={{
                              fontSize: '14px',
                              lineHeight: '23px',
                              fontWeight: '400',
                            }}>
                            {rewardVoucherDetail?.partner_name
                              ? rewardVoucherDetail?.partner_name
                              : ''}
                          </Typography>
                          <Typography
                            variant="h2s"
                            component="h2s"
                            sx={{
                              fontSize: '14px',
                              lineHeight: '23px',
                              fontWeight: '400',
                            }}>
                            website
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack
                        direction="column"
                        spacing={0.5}
                        sx={{ justifyContent: 'center', alignItems: 'center' }}>
                        {moment(
                          rewardsVoucherDetails?.expiry_date,
                        ).isBefore() ? (
                          <Box>
                            <Stack
                              direction="row"
                              spacing={0.5}
                              sx={{ alignItems: 'center' }}>
                              <Typography
                                variant="h2"
                                component="h2"
                                sx={{
                                  fontSize: '16px',
                                  lineHeight: '23px',
                                  fontWeight: '400',
                                }}>
                                Expired on{' '}
                                {moment(
                                  rewardsVoucherDetails?.expiry_date,
                                ).format('LL')}
                              </Typography>
                            </Stack>
                          </Box>
                        ) : (
                          <Box></Box>
                        )}

                        {rewardsVoucherDetails?.quantity ? (
                          <Box></Box>
                        ) : (
                          <Box>
                            <Stack
                              direction="row"
                              spacing={0.5}
                              sx={{ alignItems: 'center' }}>
                              <Typography
                                variant="h2"
                                component="h2"
                                sx={{
                                  fontSize: '16px',
                                  lineHeight: '23px',
                                  fontWeight: '400',
                                }}>
                                You Have:
                              </Typography>
                              <Typography
                                variant="h2s"
                                component="h2s"
                                sx={{
                                  fontSize: '16px',
                                  lineHeight: '23px',
                                  color: '#F5BD02',
                                  fontWeight: '600',
                                  fontFamily: 'Open Sans',
                                }}>
                                {parseInt(
                                  rewardsTotalCredits?.credits,
                                )?.toLocaleString()}
                              </Typography>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',

                                  height: '18px',
                                  maxWidth: 'min-content',
                                  width: '18px',
                                }}>
                                <img alt="creditgold" src={credits_gold} />
                              </Box>
                            </Stack>
                          </Box>
                        )}

                        {rewardsVoucherDetails?.redeemedOn ? (
                          <Box>
                            <Stack
                              direction="row"
                              spacing={0.5}
                              sx={{ alignItems: 'center' }}>
                              <Typography
                                variant="h2"
                                component="h2"
                                sx={{
                                  fontSize: '14px',
                                  lineHeight: '23px',
                                  fontWeight: '400',
                                }}>
                                {'Purchased on ' +
                                  moment(
                                    rewardsVoucherDetails?.redeemedOn,
                                  ).format('LL')}
                              </Typography>
                            </Stack>
                          </Box>
                        ) : (
                          <Box></Box>
                        )}

                        {rewardsVoucherDetails?.coupons?.length > 0 && (
                          <CxoButton
                            label={
                              <Typography
                                variant="h2"
                                component="h2"
                                sx={{
                                  fontSize: '14px',
                                  lineHeight: '23px',
                                  fontWeight: '400',
                                  fontFamily: 'open Sans',
                                  color: '#000',
                                }}>
                                View Codes
                              </Typography>
                            }
                            onClick={viewVoucher}
                          />
                        )}

                        {rewardsVoucherDetails?.quantity ? (
                          <Box>
                            <Typography
                              variant="h2"
                              component="h2"
                              sx={{
                                fontSize: '14px',
                                lineHeight: '23px',
                                fontWeight: '400',
                              }}>
                              {'Purchased on ' +
                                moment(rewardsVoucherDetails?.createdAt).format(
                                  'LL',
                                )}
                            </Typography>
                          </Box>
                        ) : (
                          rewardsVoucherDetails?.coins_to_used != 0 && (
                            <CxoButton
                              label="Purchase Now"
                              onClick={showConfirmation}
                            />
                          )
                        )}

                        {rewardsVoucherDetails?.coins_to_used === 0 && (
                          <CxoButton
                            label="Interested"
                            onClick={showInterested}
                          />
                        )}

                        {moment(
                          rewardsVoucherDetails?.expiry_date,
                        ).isAfter() ? (
                          <Box>
                            <Stack
                              direction="row"
                              spacing={0.5}
                              sx={{ alignItems: 'center' }}>
                              <Typography
                                variant="h2"
                                component="h2"
                                sx={{
                                  fontSize: '14px',
                                  lineHeight: '23px',
                                  fontWeight: '400',
                                }}>
                                {'Expires on ' +
                                  moment(
                                    rewardsVoucherDetails?.expiry_date,
                                  ).format('LL')}
                              </Typography>
                            </Stack>
                          </Box>
                        ) : (
                          <Box></Box>
                        )}
                      </Stack>
                    </Stack>
                  </Box>
                  <Box py={2}>
                    <Divider />
                  </Box>

                  <Stack direction="column" spacing={2}>
                    <Box>
                      <Typography
                        variant="h2s"
                        component="h2s"
                        sx={{
                          fontSize: '16px',
                          lineHeight: '23px',
                          fontWeight: '400',
                        }}>
                        How to redeem this offer?
                      </Typography>
                    </Box>
                    {rewardVoucherDetail?.how_to_redeem_offer ? (
                      <Box
                        sx={{
                          fontWeight: '500',
                          fontSize: '15px',
                          color: 'rgba(255, 255,255,1)',
                          whiteSpace: 'pre-line',
                          lineHeight: '21px',
                          letterSpacing: '1px',

                          '@media (max-width: 500px)': {
                            fontSize: '13px',
                          },
                        }}
                        dangerouslySetInnerHTML={{
                          __html: convertLinks(
                            rewardVoucherDetail?.how_to_redeem_offer,
                          ),
                        }}></Box>
                    ) : (
                      ''
                    )}
                  </Stack>

                  <Box py={2}>
                    <Divider />
                  </Box>
                  <Stack direction="column" spacing={2}>
                    <Box>
                      <Typography
                        variant="h2s"
                        component="h2s"
                        sx={{
                          fontSize: '16px',
                          lineHeight: '23px',
                          fontWeight: '400',
                        }}>
                        Terms & Conditions
                      </Typography>
                    </Box>

                    {rewardVoucherDetail?.tnc ? (
                      <Box
                        sx={{
                          fontWeight: '400',
                          fontSize: '15px',
                          color: 'rgba(255, 255,255,1)',
                          whiteSpace: 'pre-line',
                          lineHeight: '21px',
                          letterSpacing: '1px',

                          '@media (max-width: 500px)': {
                            fontSize: '13px',
                          },
                        }}
                        dangerouslySetInnerHTML={{
                          __html: convertLinks(rewardVoucherDetail?.tnc),
                        }}></Box>
                    ) : (
                      ''
                    )}
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Paper>
        </Box>
      </Fragment>
    );
  }
};

export default React.memo(Voucher);

//
// <CxoButton
//   disabled={moment(
//     rewardsVoucherDetails?.expiry_date
//   ).isBefore()}
//   label={
//     <Typography
//       variant="h2"
//       component="h2"
//       sx={{
//         fontSize: "14px",
//         lineHeight: "23px",
//         fontWeight: "400",
//         fontFamily: "open Sans",
//         color: "#000",
//       }}
//     >
//        Purchased
//     </Typography>
//   }
//   onClick={copyVoucher}
// />

//
// <Stack>
//   <Box
//     px={3}
//     pt={2}
//     sx={{
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       width: "100%",
//     }}
//   >
//     <Typography gutterBottom variant="h5" component="h5">
//       Are you sure you want to purchase ?
//     </Typography>
//   </Box>
//   <Stack direction="row" justifyContent="center">
//     <Box
//       py={2}
//       px={3}
//       sx={{
//         display: "flex",
//         justifyContent: "center",
//       }}
//     >
//       <CxoButton label="No" onClick={showConfirmation} />
//     </Box>
//     <Box
//       py={2}
//       px={3}
//       sx={{
//         display: "flex",
//         justifyContent: "center",
//       }}
//     >
//       <CxoButton label="Yes" onClick={purchaseVoucher} />
//     </Box>
//   </Stack>
// </Stack>

// rzp_test_bbIfwGw718hTLk

// {rewardsVoucherDetails?.coins_to_used
//   ? parseInt(
//       rewardsVoucherDetails?.coins_to_used,
//     )?.toLocaleString()
//   : ''}
