import React, { Fragment, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Paper, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import Tab from '@mui/base/Tab';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import * as rewardsActions from '../../store/rewards';
import * as companyActions from '../../store/company';
import credits_gold from '../../assets/header/Credits_Gold.png';
import CxoButton from '../../components/CxoButton/CxoButton';
import CxoButtonDisabled from '../../components/CxoButton/CxoButtonDisabled';
import { useNavigate } from 'react-router-dom';
import { dateTime } from '../../constants/app';
import moment from 'moment';
import * as messageActions from '../../store/message';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import useRazorpay from 'react-razorpay';
import axios from 'axios';
import config from '../../services/apiConfig';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));

const CorporatePackage = (props) => {
  console.log('Voucher', props);
  let params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Razorpay = useRazorpay();
  const [value, setValue] = React.useState(1);
  const [showconfirmation, setShowConfirmation] = React.useState(false);
  const [showpaymentSuccess, setShowpaymentSuccess] = React.useState(false);
  const [viewCoupons, setViewCoupons] = React.useState(false);
  const [topay, setTopay] = React.useState(0);
  const [showsuccess, setSuccess] = React.useState(false);
  const [quantity, setQuantity] = React.useState(1);
  const [disabled, setDisabled] = React.useState(false);
  const [productDetails, setProductdetails] = React.useState({});
  const user = useSelector((state) => state?.user?.UserDetails);
  // const searchresult =  dispatch(searchActions.postSearch(data));
  const companydetails = useSelector((state) => state?.company?.companyDetails);
  const rewardVoucherDetail = useSelector(
    (state) => state?.rewards?.rewardVoucherDetail,
  );

  const rewardsVoucherDetails = useSelector(
    (state) => state?.rewards?.rewardsVoucherDetails,
  );

  const rewardsTotalCredits = useSelector(
    (state) => state?.rewards?.rewardsTotalCredits,
  );

  const rewardsPartnerDetails = useSelector(
    (state) => state?.rewards?.rewardsPartnerDetails,
  );
  const rewardDetailsloading = useSelector(
    (state) => state?.rewards?.rewardDetailsloading,
  );
  const noOfAvailableCoupons = useSelector(
    (state) => state?.rewards?.noOfAvailableCoupons,
  );

  const corporateMembershipsProduct = useSelector(
    (state) => state?.rewards?.corporateMembershipsProduct,
  );

  const corporateMembershipProductDetails = useSelector(
    (state) => state?.rewards?.corporateMembershipProductDetails,
  );

  const companyVoucherDetails = useSelector(
    (state) => state?.company?.companyVoucherDetails,
  );
  useEffect(async () => {}, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(async () => {
    const data = {
      category_id: 0,
    };
    const rewardspartners = await dispatch(
      rewardsActions.rewardsPartnerList(data),
    );
  }, []);

  useEffect(async () => {
    const id = params?.id;
    var result = corporateMembershipsProduct.filter((obj) => {
      return obj.id == id;
    });

    setProductdetails(result[0]);

    const data = {
      product_id: id,
    };
    const noofavailable = await dispatch(
      rewardsActions.corporateMembershipProductDetails(data),
    );
  }, []);

  useEffect(() => {
    async function fetchData() {
      const id = params?.id;
      const data = {
        product_id: id,
      };
      const responsedetails = await dispatch(
        companyActions.getCompanyVoucherDetails(data),
      );
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const data = {
        membership_id: 0,
      };
      const responsedetails = await dispatch(
        rewardsActions.rewardMemberDetails(data),
      );
    }
    fetchData();
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
      product_id: rewardsVoucherDetails?.id,
      datetime: dateTime(),
    };
    const rewardVoucherDetails = await dispatch(
      rewardsActions.rewardsVoucherDetails(data),
    );
    if (rewardVoucherDetails?.status === 1) {
      const dataVoucher = {
        product_id: rewardVoucherDetails?.data?.id,
      };
      const noofavailable = await dispatch(
        rewardsActions.rewardsNoOfVoucher(dataVoucher),
      );
    }
  }, []);

  const increaseQuantity = () => {
    setQuantity(parseInt(quantity) + 1);
  };
  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(parseInt(quantity) - 1);
    }
  };
  const showConfirmation = () => {
    setShowConfirmation(!showconfirmation);
  };

  const closeViewCoupons = () => {
    setViewCoupons(false);
  };

  const quantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const purchaseNow = () => {};

  const toPay = () => {
    var final_coin = rewardVoucherDetail?.coins_to_used;
    if (user?.membership_type == 0) {
      final_coin =
        parseInt(rewardVoucherDetail?.coins_to_used) -
        parseInt(
          parseInt(rewardVoucherDetail?.coins_to_used) *
            (rewardVoucherDetail?.basic_offer_per / 100),
        );
    } else if (user?.membership_type == 2) {
      final_coin =
        parseInt(rewardVoucherDetail?.coins_to_used) -
        parseInt(
          parseInt(rewardVoucherDetail?.coins_to_used) *
            (rewardVoucherDetail?.silver_offer_per / 100),
        );
    } else if (user?.membership_type == 3) {
      final_coin =
        parseInt(rewardVoucherDetail?.coins_to_used) -
        parseInt(
          parseInt(rewardVoucherDetail?.coins_to_used) *
            (rewardVoucherDetail?.gold_offer_per / 100),
        );
    } else if (user?.membership_type == 4) {
      final_coin =
        parseInt(rewardVoucherDetail?.coins_to_used) -
        parseInt(
          parseInt(rewardVoucherDetail?.coins_to_used) *
            (rewardVoucherDetail?.platinum_offer_per / 100),
        );
    } else {
      final_coin = parseInt(rewardVoucherDetail?.coins_to_used);
    }

    let topay =
      parseInt(rewardsTotalCredits?.credits) / 10 -
      (parseInt(final_coin) / 10) * quantity;
    if (
      parseInt(rewardsTotalCredits?.credits) / 10 >=
      (parseInt(final_coin) / 10) * quantity
    ) {
      return '0';
    } else {
      // setTopay(   ((parseInt(rewardsVoucherDetails?.coins_to_used) / 10) * quantity -parseInt(rewardsTotalCredits?.credits) / 10).toFixed(2) * 100 )
      if (parseInt(rewardsTotalCredits?.credits) < 0) {
        return ((parseInt(final_coin) / 10) * quantity).toFixed(2);
      } else {
        return (
          (parseInt(final_coin) / 10) * quantity -
          parseInt(rewardsTotalCredits?.credits) / 10
        ).toFixed(2);
      }
    }
  };

  const checkBalance = () => {
    var final_coin = rewardVoucherDetail?.coins_to_used;
    if (user?.membership_type == 0) {
      final_coin =
        parseInt(rewardVoucherDetail?.coins_to_used) -
        parseInt(
          parseInt(rewardVoucherDetail?.coins_to_used) *
            (rewardVoucherDetail?.basic_offer_per / 100),
        );
    } else if (user?.membership_type == 2) {
      final_coin =
        parseInt(rewardVoucherDetail?.coins_to_used) -
        parseInt(
          parseInt(rewardVoucherDetail?.coins_to_used) *
            (rewardVoucherDetail?.silver_offer_per / 100),
        );
    } else if (user?.membership_type == 3) {
      final_coin =
        parseInt(rewardVoucherDetail?.coins_to_used) -
        parseInt(
          parseInt(rewardVoucherDetail?.coins_to_used) *
            (rewardVoucherDetail?.gold_offer_per / 100),
        );
    } else if (user?.membership_type == 4) {
      final_coin =
        parseInt(rewardVoucherDetail?.coins_to_used) -
        parseInt(
          parseInt(rewardVoucherDetail?.coins_to_used) *
            (rewardVoucherDetail?.platinum_offer_per / 100),
        );
    } else {
      final_coin = parseInt(rewardVoucherDetail?.coins_to_used);
    }

    if (
      parseInt(rewardsTotalCredits?.credits) / 10 >=
      (parseInt(final_coin) / 10) * quantity
    )
      return true;
    else {
      return false;
    }
  };
  const purchaseVoucher = async () => {
    const data = {
      user_id: user?.id,
      product_id: rewardsVoucherDetails?.id,
      quantity: quantity,
      partner_id: rewardVoucherDetail?.partner_id,
      datetime: dateTime(),
    };
    const rewardsredeem = await dispatch(
      rewardsActions.rewardsRedeemVoucher(data),
    );
    if (rewardsredeem?.status === 1) {
      setShowpaymentSuccess(false);
      setSuccess(true);

      const datavoucher = {
        user_id: user?.id,
        product_id: rewardsVoucherDetails?.id,
        datetime: dateTime(),
      };
      const rewardVoucherDetails = await dispatch(
        rewardsActions.rewardsVoucherDetails(datavoucher),
      );
    }
  };

  const payVoucher = () => {};
  const visitWebsite = () => {
    if (rewardVoucherDetail.partner_website) {
      window.open(rewardVoucherDetail.partner_website, '_blank');
    }
  };

  const copyVoucher = () => {
    navigator.clipboard.writeText(rewardsVoucherDetails?.voucher_code);
    document.execCommand('copy');
  };

  const redirectVoucher = () => {
    navigate('/rewards/my/vouchers', { state: { tab: 1 } });
    setShowConfirmation(!showconfirmation);
  };

  const razorPaySuccess = async (res) => {
    // alert("Payment Sucessfully", res);
    // const data = {
    //   user_id: user?.id,
    //   product_id: rewardsVoucherDetails?.id,
    //   datetime: dateTime(),
    // };
    // const rewardVoucherDetails = await dispatch(
    //   rewardsActions.rewardsVoucherDetails(data),
    // );
    setShowpaymentSuccess(true);
    setShowConfirmation(true);
    setDisabled(false);
    // purchaseVoucher();
  };

  const razorPayFailed = (error) => {
    console.log('payment failed', error);
    setDisabled(false);
  };
  const orderId = () => {};
  const copyLink = async (code) => {
    if (user?.isVerified) {
      navigator.clipboard.writeText(code);
      document.execCommand('copy');
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const viewVoucher = () => {
    setViewCoupons(true);
  };

  const convertLinks = (input) => {
    console.log('convertLinks', input);
    if (input) {
      let text = input;
      const linksFound = text.match(/(?:www|https?)[^\s]+/g);
      const aLink = [];

      if (linksFound != null) {
        for (let i = 0; i < linksFound.length; i++) {
          let replace = linksFound[i];
          if (!linksFound[i].match(/(http(s?)):\/\//)) {
            replace = 'http://' + linksFound[i];
          }
          let linkText = replace.split('/')[2];
          let originalText = linksFound[i];
          if (linkText.substring(0, 3) == 'www') {
            linkText = linkText.replace('www.', '');
          }
          if (linkText.match(/youtu/)) {
            let youtubeID = replace.split('/').slice(-1)[0];
            aLink.push(
              '<div class="video-wrapper"><iframe src="https://www.youtube.com/embed/' +
                youtubeID +
                '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
            );
          } else if (linkText.match(/vimeo/)) {
            let vimeoID = replace.split('/').slice(-1)[0];
            aLink.push(
              '<div class="video-wrapper"><iframe src="https://player.vimeo.com/video/' +
                vimeoID +
                '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>',
            );
          } else {
            aLink.push(
              '<a href="' +
                replace +
                '" target="_blank">' +
                originalText +
                '</a>',
            );
          }
          text = text
            .split(linksFound[i])
            .map((item) => {
              return aLink[i].includes('iframe') ? item.trim() : item;
            })
            .join(aLink[i]);
        }
        return text;
      } else {
        return input;
      }
    }
  };

  const purchasingAmount = () => {
    console.log('purchasingAmount', user);
    if (user?.membership_type == 0) {
      return (
        'Rs' +
        (parseInt(rewardVoucherDetail?.mininum_amount_to_redeem) -
          parseInt(
            parseInt(rewardVoucherDetail?.mininum_amount_to_redeem) *
              (rewardVoucherDetail?.basic_offer_per / 100),
          ))
      );
    } else if (user?.membership_type == 2) {
      return (
        parseInt(rewardVoucherDetail?.mininum_amount_to_redeem) -
        parseInt(
          parseInt(rewardVoucherDetail?.mininum_amount_to_redeem) *
            (rewardVoucherDetail?.silver_offer_per / 100),
        )
      );
    } else if (user?.membership_type == 3) {
      return (
        parseInt(rewardVoucherDetail?.mininum_amount_to_redeem) -
        parseInt(
          parseInt(rewardVoucherDetail?.mininum_amount_to_redeem) *
            (rewardVoucherDetail?.gold_offer_per / 100),
        )
      );
    } else if (user?.membership_type == 4) {
      return (
        parseInt(rewardVoucherDetail?.mininum_amount_to_redeem) -
        parseInt(
          parseInt(rewardVoucherDetail?.mininum_amount_to_redeem) *
            (rewardVoucherDetail?.platinum_offer_per / 100),
        )
      );
    } else {
      return 'Rs' + parseInt(rewardVoucherDetail?.mininum_amount_to_redeem);
    }
    // if(membership_type
    // parseInt(rewardsVoucherDetails?.mininum_amount_to_redeem) *
  };

  const purchasingCoins = () => {
    if (user?.membership_type == 0) {
      return (
        parseInt(rewardVoucherDetail?.coins_to_used) -
        parseInt(
          parseInt(rewardVoucherDetail?.coins_to_used) *
            (rewardVoucherDetail?.basic_offer_per / 100),
        )
      );
    } else if (user?.membership_type == 1) {
      return (
        parseInt(rewardVoucherDetail?.coins_to_used) -
        parseInt(
          parseInt(rewardVoucherDetail?.coins_to_used) *
            (rewardVoucherDetail?.basic_offer_per / 100),
        )
      );
    } else if (user?.membership_type == 2) {
      return (
        parseInt(rewardVoucherDetail?.coins_to_used) -
        parseInt(
          parseInt(rewardVoucherDetail?.coins_to_used) *
            (rewardVoucherDetail?.silver_offer_per / 100),
        )
      );
    } else if (user?.membership_type == 3) {
      return (
        parseInt(rewardVoucherDetail?.coins_to_used) -
        parseInt(
          parseInt(rewardVoucherDetail?.coins_to_used) *
            (rewardVoucherDetail?.gold_offer_per / 100),
        )
      );
    } else if (user?.membership_type == 4) {
      return (
        parseInt(rewardVoucherDetail?.coins_to_used) -
        parseInt(
          parseInt(rewardVoucherDetail?.coins_to_used) *
            (rewardVoucherDetail?.platinum_offer_per / 100),
        )
      );
    } else {
      return (
        parseInt(rewardVoucherDetail?.coins_to_used) -
        parseInt(
          parseInt(rewardVoucherDetail?.coins_to_used) *
            (rewardVoucherDetail?.basic_offer_per / 100),
        )
      );
    }
    // if(membership_type
    // parseInt(rewardsVoucherDetails?.mininum_amount_to_redeem) *
  };

  const handlePayment = useCallback(() => {
    setDisabled(true);
    const order = 455;
    let topay = 0;
    if (
      parseInt(rewardsTotalCredits?.credits) / 10 >=
      (parseInt(purchasingCoins()) / 10) * quantity
    ) {
      topay = 0;
      console.log(
        'pay',
        rewardsTotalCredits?.credits,
        (parseInt(rewardsVoucherDetails?.coins_to_used) / 10) * quantity,
        quantity,
        topay,
      );
    } else {
      // setTopay(   ((parseInt(rewardsVoucherDetails?.coins_to_used) / 10) * quantity -parseInt(rewardsTotalCredits?.credits) / 10).toFixed(2) * 100 )
      if (parseInt(rewardsTotalCredits?.credits) > 0) {
        topay =
          (
            (parseInt(purchasingCoins()) / 10) * quantity -
            parseInt(rewardsTotalCredits?.credits) / 10
          ).toFixed(2) * 100;
      } else {
        topay =
          (
            (parseInt(rewardsVoucherDetails?.coins_to_used) / 10) *
            quantity
          ).toFixed(2) * 100;
      }
      console.log('pay', rewardsTotalCredits?.credits, topay);
    }
    console.log('pay', quantity, topay);
    // rzp_test_0uFcavHKXDrPCV rzp_live_S9XA2i9Ox4zjp5
    let options = {
      key: 'rzp_test_0uFcavHKXDrPCV',
      amount: parseInt(topay),
      currency: 'INR',
      name: 'Linkcxo',
      description: 'Membership',
      image: 'https://linkcxo-logo.s3.ap-south-1.amazonaws.com/logo.png',
      order_id: '',
      handler: (res) => {
        return razorPaySuccess();
        console.log(res);
      },
      prefill: {
        name: user?.first_name + ' ' + user?.last_name,
        email: user?.email,
        contact: user?.mobile,
      },
      notes: {
        address: '',
      },
      theme: {
        color: '#38CD84',
      },
    };

    axios
      .post(config?.serverBaseUrl + '/create_order/corporate_membership', {
        amount: parseInt(topay),
        currency: 'INR',
        receipt: 'rec_pewep34' + user?.id,
        notes: 'lang',
        user_id: user?.id,
        tag: 'credit',
        offer_id: rewardVoucherDetail?.id,
      })
      .then((res) => {
        console.log('res', res);
        setShowConfirmation(false);
        options.order_id = res.data.id;
        options.amount = res.data.amount;
        console.log(options);
        const rzpay = new Razorpay(options);
        rzpay.on('payment.failed', function (response) {
          return razorPayFailed(response);
        });
        rzpay.open();
      })
      .catch((e) => console.log(e));
  }, [Razorpay, quantity, razorPaySuccess, razorPayFailed]);

  const buyMembership = useCallback(() => {
    axios
      .post(config?.serverBaseUrl + '/create_order/corporate_membership', {
        currency: 'INR',
        receipt: 'rec_pewep34',
        notes: 'lang',
        user_id: user?.id,
        user_table_pk: companydetails?.id,
        user_type: 'company_page',
        membership_product_id: parseInt(params?.id),
        membership_id: parseInt(productDetails?.membership_id),
      })
      .then((res) => {
        let options = {
          key: 'rzp_test_0uFcavHKXDrPCV',
          amount: res.data.amount_due,
          currency: 'INR',
          name: 'Linkcxo',
          description: 'Membership',
          image: 'https://linkcxo-logo.s3.ap-south-1.amazonaws.com/logo.png',
          order_id: res.data.id,
          handler: (res) => {
            console.log('Razorpay', res);
            return razorPaySuccess();
          },
          prefill: {
            name: user?.first_name + ' ' + user?.last_name,
            email: user?.email,
            contact: user?.mobile,
          },
          notes: {
            address: '',
          },
          theme: {
            color: '#38CD84',
          },
        };
        console.log('options', options);
        const rzpay = new Razorpay(options);
        rzpay.on('payment.failed', function (response) {
          return razorPayFailed(response);
        });
        rzpay.open();
      })
      .catch((e) => console.log(e));
  }, [Razorpay, razorPaySuccess, razorPayFailed]);

  return (
    <Fragment>
      {viewCoupons && (
        <Box
          sx={{
            width: '100%',
            height: '100vh',
            position: 'fixed',
            zIndex: '3001',
          }}>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={viewCoupons}>
            <Box
              sx={{
                width: '80%',
                backgroundColor: '#1c1c1c',
                borderRadius: '10px',
                maxWidth: '700px',
              }}>
              <Stack spacing={0} sx={{ width: '100%', maxWidth: '700px' }}>
                <Fragment>
                  <Stack spacing={0} direction="row">
                    <Box
                      px={3}
                      pt={2}
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginLeft: '24px',
                      }}></Box>
                    <Box
                      px={3}
                      pt={2}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <CloseIcon
                        sx={{
                          color: '#ffffff',
                          cursor: 'pointer',
                          fontSize: '24',
                        }}
                        onClick={closeViewCoupons}
                      />
                    </Box>
                  </Stack>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      paddingBottom: '64px',
                    }}>
                    <Stack
                      direction="column"
                      spacing={2}
                      sx={{ justifyContent: 'center', alignItems: 'center' }}>
                      <Box>
                        <Typography variant="h2s" component="h2s">
                          Voucher Codes
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          width: '100%',
                          maxHeight: '60vh',
                          overflowY: 'auto',
                        }}>
                        {rewardsVoucherDetails?.coupons?.length > 0 &&
                          rewardsVoucherDetails?.coupons?.map((code, index) => {
                            return (
                              <Box
                                onClick={() => copyLink(code)}
                                sx={{
                                  width: '300px',
                                  height: '40px',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  borderRadius: '25px',
                                  backgroundColor: '#333',
                                  border: '1px solid #3DCD84',
                                }}>
                                <Box
                                  p={1}
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}>
                                  <Typography variant="h2s" component="h2s">
                                    {code?.voucher_code}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    backgroundColor: '#38CD84',
                                    color: '#262525',
                                    width: '72px',
                                    borderRadius: '25px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}>
                                  <Typography variant="h2" component="h2">
                                    Copy
                                  </Typography>
                                </Box>
                              </Box>
                            );
                          })}
                      </Box>
                    </Stack>
                  </Box>
                </Fragment>
              </Stack>
            </Box>
          </Backdrop>
        </Box>
      )}

      {showconfirmation && (
        <Box
          sx={{
            width: '100%',
            height: '100vh',
            position: 'fixed',
            zIndex: '3001',
          }}>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showconfirmation}>
            <Box
              sx={{
                width: '80%',
                backgroundColor: '#1c1c1c',
                borderRadius: '10px',
                maxWidth: '700px',
              }}>
              <Stack spacing={0} sx={{ width: '100%', maxWidth: '700px' }}>
                {!showsuccess && (
                  <Fragment>
                    <Stack spacing={0} direction="row">
                      <Box
                        px={3}
                        pt={2}
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: '24px',
                        }}></Box>
                      <Box
                        px={3}
                        pt={2}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <CloseIcon
                          sx={{
                            color: '#ffffff',
                            cursor: 'pointer',
                            fontSize: '24',
                          }}
                          onClick={showConfirmation}
                        />
                      </Box>
                    </Stack>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}>
                      <Stack
                        direction="column"
                        spacing={2}
                        sx={{
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        {!showpaymentSuccess && (
                          <Typography variant="h2s" component="h2s">
                            {checkBalance()
                              ? 'Purchase Voucher'
                              : "You don't have sufficient  points to purchase this voucher"}
                          </Typography>
                        )}

                        {noOfAvailableCoupons === 0 && (
                          <Typography variant="h2s" component="h2s">
                            Coupon will be sent to you within 24 Hours
                          </Typography>
                        )}
                      </Stack>
                    </Box>
                  </Fragment>
                )}

                {showpaymentSuccess && (
                  <Stack>
                    <Box
                      px={3}
                      pt={4}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                      }}>
                      <Typography gutterBottom variant="h5" component="h5">
                        Payment Successful
                      </Typography>
                    </Box>
                    <Stack direction="row" justifyContent="center">
                      <Box
                        py={2}
                        px={3}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}></Box>
                    </Stack>
                  </Stack>
                )}
                {showsuccess && (
                  <Stack>
                    <Box
                      px={3}
                      pt={4}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                      }}>
                      <Typography gutterBottom variant="h5" component="h5">
                        Thank you for purchasing this voucher. Please check your
                        email for the redeeming process.
                      </Typography>
                    </Box>
                    <Stack direction="row" justifyContent="center">
                      <Box
                        py={2}
                        px={3}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}>
                        <CxoButton label="OK" onClick={redirectVoucher} />
                      </Box>
                    </Stack>
                  </Stack>
                )}

                {!showsuccess && !showpaymentSuccess && (
                  <Stack>
                    <Box
                      px={3}
                      pt={2}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                      }}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h5"></Typography>
                    </Box>
                    <Stack direction="row" justifyContent="space-between">
                      <Box pl={3}>
                        <Stack direction="row" spacing={2}>
                          <Typography variant="h2" component="h2">
                            You have
                          </Typography>
                          <Stack direction="row" spacing={0.5}>
                            <Typography
                              variant="h2"
                              component="h2"
                              sx={{
                                color: '#F5BD02',
                                fontFamily: 'Open Sans',
                              }}>
                              {rewardsTotalCredits?.credits
                                ? parseInt(
                                    rewardsTotalCredits?.credits,
                                  )?.toLocaleString()
                                : parseInt(
                                    rewardsTotalCredits?.credits,
                                  )?.toLocaleString()}
                            </Typography>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',

                                height: '14px',
                                maxWidth: 'min-content',
                                width: '14px',
                                paddingTop: '4px',
                              }}>
                              <img alt="creditgold" src={credits_gold} />
                            </Box>
                          </Stack>
                        </Stack>
                      </Box>

                      <Box pr={3}>
                        <Typography
                          variant="h2s"
                          component="h2s"
                          sx={{
                            color: '#F5BD02',
                            fontFamily: 'Open Sans',
                          }}>
                          Rs{' '}
                          {rewardsTotalCredits?.credits
                            ? (
                                parseInt(rewardsTotalCredits?.credits) / 10
                              )?.toLocaleString()
                            : 0}
                        </Typography>
                      </Box>
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{ paddingTop: '16px' }}>
                      <Box pl={3}>
                        <Stack direction="row" spacing={2}>
                          <Typography variant="h2" component="h2">
                            Voucher Price
                          </Typography>
                          <Stack direction="row" spacing={0.5}>
                            <Typography
                              variant="h2"
                              component="h2"
                              sx={{
                                color: '#F5BD02',
                                fontFamily: 'Open Sans',
                              }}>
                              {purchasingCoins()
                                ? parseInt(purchasingCoins())?.toLocaleString()
                                : 0}
                            </Typography>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '14px',
                                maxWidth: 'min-content',
                                width: '14px',
                                paddingTop: '4px',
                              }}>
                              <img alt="creditgold" src={credits_gold} />
                            </Box>
                          </Stack>
                        </Stack>
                      </Box>

                      <Box pr={3}>
                        <Typography
                          variant="h2s"
                          component="h2s"
                          sx={{
                            color: '#F5BD02',
                            fontFamily: 'Open Sans',
                          }}>
                          Rs{' '}
                          {purchasingCoins()
                            ? (
                                parseInt(purchasingCoins()) / 10
                              )?.toLocaleString()
                            : 0}
                        </Typography>
                      </Box>
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{ paddingTop: '16px' }}>
                      <Box pl={3}>
                        <Stack direction="row" spacing={2}>
                          <Typography variant="h2" component="h2">
                            Select Quantity
                          </Typography>
                          <Box pl={1} sx={{ zIndex: '3000' }}>
                            <div className="input-counter">
                              <span
                                className="minus-btn"
                                onClick={decreaseQuantity}>
                                <RemoveIcon sx={{ color: '#000' }} />
                              </span>
                              <input
                                type="text"
                                value={quantity}
                                onChange={quantityChange}
                              />
                              <span
                                className="plus-btn"
                                onClick={increaseQuantity}>
                                <AddIcon sx={{ color: '#000' }} />
                              </span>
                            </div>
                          </Box>
                        </Stack>
                      </Box>

                      <Box pr={3}>
                        <Typography
                          variant="h2s"
                          component="h2s"
                          sx={{
                            color: '#F5BD02',
                            fontFamily: 'Open Sans',
                          }}>
                          Rs{' '}
                          {purchasingCoins()
                            ? (
                                (parseInt(purchasingCoins()) / 10) *
                                quantity
                              )?.toLocaleString()
                            : 0}
                        </Typography>
                      </Box>
                    </Stack>

                    {/* <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{ paddingTop: '16px' }}>
                        <Box pl={3}>
                          <Stack direction="row" spacing={2}>
                            <Typography variant="h2" component="h2">
                              Select Quantity
                            </Typography>
                            <Box pl={1} sx={{ zIndex: '3000' }}>
                              <FormControl
                                variant="standard"
                                fullWidth
                                sx={{ zIndex: '3000' }}>
                                <select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={quantity}
                                  label="Industry List"
                                  onChange={quantityChange}
                                  size="small"
                                  sx={{ width: '96%', zIndex: '3000' }}
                                  style={{
                                    backgroundColor: '#1f1e1e',
                                    color: '#ffffff',
                                  }}>
                                  {noOfAvailableCoupons > 5 && (
                                    <>
                                      <option value="1" sx={{ zIndex: '3000' }}>
                                        1
                                      </option>
                                      <option value="2" sx={{ zIndex: '3000' }}>
                                        2
                                      </option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                      <option value="5">5</option>
                                    </>
                                  )}

                                  {noOfAvailableCoupons <= 5 &&
                                    noOfAvailableCoupons >= 0 &&
                                    [...Array(noOfAvailableCoupons)].map(
                                      (elementInArray, index) => (
                                        <option
                                          value={index + 1}
                                          sx={{ zIndex: '3000' }}>
                                          {index + 1}
                                        </option>
                                      ),
                                    )}
                                  {noOfAvailableCoupons === 0 && (
                                    <>
                                      <option value="1" sx={{ zIndex: '3000' }}>
                                        1
                                      </option>
                                      <option value="2" sx={{ zIndex: '3000' }}>
                                        2
                                      </option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                      <option value="5">5</option>
                                    </>
                                  )}
                                </select>
                              </FormControl>
                            </Box>
                          </Stack>
                        </Box>

                        <Box pr={3}>
                          <Typography
                            variant="h2s"
                            component="h2s"
                            sx={{
                              color: '#F5BD02',

                              fontFamily: 'Open Sans',
                            }}>
                            Rs{' '}
                            {rewardsVoucherDetails?.coins_to_used
                              ? (
                                  (parseInt(
                                    rewardsVoucherDetails?.coins_to_used,
                                  ) /
                                    10) *
                                  quantity
                                )?.toLocaleString()
                              : 0}
                          </Typography>
                        </Box>
                      </Stack> */}

                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{ paddingTop: '16px' }}>
                      <Box pl={3}>
                        <Stack>
                          <Stack direction="row" spacing={2}>
                            <Typography variant="h2" component="h2">
                              To pay
                            </Typography>
                            <Stack direction="row" spacing={0.5}>
                              <Typography
                                variant="h2"
                                component="h2"
                                sx={{
                                  color: '#F5BD02',

                                  fontFamily: 'Open Sans',
                                }}>
                                {purchasingCoins()
                                  ? (
                                      parseInt(purchasingCoins()) * quantity
                                    )?.toLocaleString()
                                  : 0}
                              </Typography>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  height: '14px',
                                  maxWidth: 'min-content',
                                  width: '14px',
                                  paddingTop: '4px',
                                }}>
                                <img alt="creditgold" src={credits_gold} />
                              </Box>
                            </Stack>
                          </Stack>
                          <Box pt={0.5}>
                            <Typography variant="h2" component="h2">
                              (10 Points = Rs. 1)
                            </Typography>
                          </Box>
                        </Stack>
                      </Box>

                      <Box pr={3}>
                        <Typography
                          variant="h2s"
                          component="h2s"
                          sx={{
                            color: '#F5BD02',
                            fontFamily: 'Open Sans',
                          }}>
                          Rs {toPay()}
                        </Typography>
                      </Box>
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="center"
                      sx={{ paddingTop: '16px' }}>
                      <Box
                        py={2}
                        px={3}
                        pb={5}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}>
                        {checkBalance() && !disabled ? (
                          <CxoButton
                            label="Purchase"
                            onClick={purchaseVoucher}
                          />
                        ) : (
                          <CxoButton
                            label={'Pay ' + toPay()}
                            onClick={handlePayment}
                          />
                        )}

                        {checkBalance() && disabled ? (
                          <CxoButtonDisabled
                            label={'Pay ' + toPay()}
                            onClick={purchaseVoucher}
                          />
                        ) : (
                          ''
                        )}
                      </Box>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Box>
          </Backdrop>
        </Box>
      )}

      <Box px={1} py={1}>
        <Paper sx={{ backgroundColor: '#262525', backgroundImage: 'none' }}>
          <Card
            sx={{
              width: '100%',
              position: 'relative',
              backgroundColor: '#262525',
              backgroundImage: 'none',
            }}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '60px',
              }}>
              <Typography
                variant="h5s"
                component="h5s"
                sx={{
                  fontSize: '18px',
                  lineHeight: '18px',
                  letterSpacing: '0.23px',
                  fontWeight: '600',
                }}></Typography>
            </Box>
            <CardMedia
              component="img"
              alt=""
              height="300"
              sx={{
                objectFit: 'contain',
              }}
              image={
                corporateMembershipProductDetails?.image
                  ? corporateMembershipProductDetails?.image
                  : ''
              }
            />
            <CardContent>
              <Box p={2}>
                <Stack direction="column">
                  <Box>
                    <Typography
                      variant="h2s"
                      component="h2s"
                      sx={{
                        fontSize: '20px',
                        lineHeight: '23px',
                        letterSpacing: '0.31px',
                        fontWeight: '600',
                      }}>
                      {corporateMembershipProductDetails?.title
                        ? corporateMembershipProductDetails?.title
                        : ''}
                    </Typography>
                  </Box>

                  <Box pt={1}>
                    {corporateMembershipProductDetails?.details ? (
                      <Box
                        sx={{
                          fontWeight: '500',
                          fontSize: '15px',
                          color: 'rgba(255, 255,255,1)',
                          whiteSpace: 'pre-line',
                          lineHeight: '21px',
                          letterSpacing: '1px',

                          '@media (max-width: 500px)': {
                            fontSize: '13px',
                          },
                        }}
                        dangerouslySetInnerHTML={{
                          __html: convertLinks(
                            corporateMembershipProductDetails?.details,
                          ),
                        }}></Box>
                    ) : (
                      ''
                    )}
                  </Box>
                  <Box py={2}>
                    <Divider />
                  </Box>
                  <Box></Box>
                </Stack>

                <Stack direction="column" spacing={2}>
                  <Box>
                    <Typography
                      variant="h2s"
                      component="h2s"
                      sx={{
                        fontSize: '16px',
                        lineHeight: '23px',
                        fontWeight: '400',
                      }}>
                      Details
                    </Typography>
                  </Box>

                  {companyVoucherDetails?.map((details, index) => {
                    return (
                      <Stack direction="row">
                        <Box sx={{ width: '50%' }}>
                          <Stack direction="column" spacing={1}>
                            <Box>
                              <Typography
                                variant="h6"
                                component="h6"
                                sx={{
                                  fontSize: '16px',
                                  lineHeight: '23px',
                                  fontWeight: '400',
                                }}>
                                Purchased:{' '}
                                {moment(details.purchased_on).format('ll')}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="h6"
                                component="h6"
                                sx={{
                                  fontSize: '16px',
                                  lineHeight: '23px',
                                  fontWeight: '400',
                                }}>
                                Activation Expiry:{' '}
                                {moment(details.activation_expiry).format('ll')}
                              </Typography>
                            </Box>
                          </Stack>
                        </Box>
                        <Box sx={{ width: '50%' }}>
                          <Stack direction="column">
                            <Box>
                              <Typography
                                variant="h6"
                                component="h6"
                                sx={{
                                  fontSize: '16px',
                                  lineHeight: '23px',
                                  fontWeight: '400',
                                }}>
                                Membership Expiry:{' '}
                                {moment(details.activation_expiry)
                                  .add(365, 'days')
                                  .format('ll')}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="h6"
                                component="h6"
                                sx={{
                                  fontSize: '16px',
                                  lineHeight: '23px',
                                  fontWeight: '400',
                                }}></Typography>
                            </Box>
                          </Stack>
                        </Box>
                      </Stack>
                    );
                  })}

                  <Box py={2}>
                    <Divider />
                  </Box>
                </Stack>
                <Stack direction="column" spacing={2}>
                  <Box>
                    <Typography
                      variant="h2s"
                      component="h2s"
                      sx={{
                        fontSize: '16px',
                        lineHeight: '23px',
                        fontWeight: '400',
                      }}>
                      Terms & Conditions
                    </Typography>
                  </Box>
                  {corporateMembershipProductDetails?.tnc ? (
                    <Box
                      sx={{
                        fontWeight: '400',
                        fontSize: '15px',
                        color: 'rgba(255, 255,255,1)',
                        whiteSpace: 'pre-line',
                        lineHeight: '21px',
                        letterSpacing: '1px',

                        '@media (max-width: 500px)': {
                          fontSize: '13px',
                        },
                      }}
                      dangerouslySetInnerHTML={{
                        __html: convertLinks(productDetails?.tnc),
                      }}></Box>
                  ) : (
                    ''
                  )}
                </Stack>
              </Box>
            </CardContent>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                direction: 'row',
                paddingLeft: '8px',
                marginTop: '8px',
                backgroundColor: '#3E3D3D',
                alignItems: 'center',
                borderBottomLeftRadius: '10px',
                borderBottomRightRadius: '10px',
              }}>
              {' '}
              <Box
                px={2}
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                }}>
                <Stack direction="column">
                  <Stack direction="row">
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{
                        fontSize: '18px',
                        lineHeight: '18px',
                        letterSpacing: '0.23px',
                        fontWeight: '600',
                      }}>
                      Membership Price for{' '}
                      {corporateMembershipProductDetails?.quantity} :{' '}
                    </Typography>
                    <Typography
                      variant="h5s"
                      component="h5s"
                      sx={{
                        fontSize: '18px',
                        fontFamily: 'Open Sans',
                        lineHeight: '18px',
                        letterSpacing: '0.23px',
                        fontWeight: '600',
                        paddingLeft: '8px',
                      }}>
                      {'  '}₹{' '}
                      {(
                        parseInt(corporateMembershipProductDetails?.price) -
                        parseInt(corporateMembershipProductDetails?.price) *
                          (corporateMembershipProductDetails?.offer_percentage /
                            100)
                      ).toLocaleString()}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
              <Box pb={1} pt={0.5} pr={2}>
                <CxoButton onClick={buyMembership} label="Purchase Now" />
                {/* {details[0]?.id > 1 ? (
                    <CxoButton onClick={buyMembership} label="BUY" />
                  ) : (
                    <CxoButtonDisabled label="BUY" />
                  )} */}
              </Box>
            </Box>
          </Card>
        </Paper>
      </Box>
    </Fragment>
  );
};

export default React.memo(CorporatePackage);

//
// <CxoButton
//   disabled={moment(
//     rewardsVoucherDetails?.expiry_date
//   ).isBefore()}
//   label={
//     <Typography
//       variant="h2"
//       component="h2"
//       sx={{
//         fontSize: "14px",
//         lineHeight: "23px",
//         fontWeight: "400",
//         fontFamily: "open Sans",
//         color: "#000",
//       }}
//     >
//        Purchased
//     </Typography>
//   }
//   onClick={copyVoucher}
// />

//
// <Stack>
//   <Box
//     px={3}
//     pt={2}
//     sx={{
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       width: "100%",
//     }}
//   >
//     <Typography gutterBottom variant="h5" component="h5">
//       Are you sure you want to purchase ?
//     </Typography>
//   </Box>
//   <Stack direction="row" justifyContent="center">
//     <Box
//       py={2}
//       px={3}
//       sx={{
//         display: "flex",
//         justifyContent: "center",
//       }}
//     >
//       <CxoButton label="No" onClick={showConfirmation} />
//     </Box>
//     <Box
//       py={2}
//       px={3}
//       sx={{
//         display: "flex",
//         justifyContent: "center",
//       }}
//     >
//       <CxoButton label="Yes" onClick={purchaseVoucher} />
//     </Box>
//   </Stack>
// </Stack>

// rzp_test_bbIfwGw718hTLk

// {rewardsVoucherDetails?.coins_to_used
//   ? parseInt(
//       rewardsVoucherDetails?.coins_to_used,
//     )?.toLocaleString()
//   : ''}
